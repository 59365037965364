import styled from 'styled-components';

export const GoogleMapSt = styled.div`
  padding: 60px 18px;
  width: 100%;
  background: '#f8f5f2';
`;
// (({ theme }) => ({
//   paddingTop: 60,
//   paddingBottom: 60,
//   paddingLeft: 18,
//   paddingRight: 18,
//   width: '100%',
//   background: '#f8f5f2',
//   [theme.breakpoints.up('md')]: {
//     paddingLeft: 8,
//     paddingRight: 8,
//   },
// }));
