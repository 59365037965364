import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";

import { Backdrop, Container } from "../styledComponent/sc";
import antoki from "../images/antoki-bg.png";
import antokiHL from "../images/antoki-hl.png";
import Button from "../components/button";
import Menu from "../components/menu";
import Highlight from "../components/highlight";
import OpenTime from "../components/opentime";
import SEO from "../components/seo";
import Pdf from "../ultilities/antoki-menu.pdf";
import { GoogleMap } from "../components/GoogleMap/GoogleMap";
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="HOME 🥙" />
      <Backdrop imgUrl={antoki} overlay height="100vh" background="#ffebc9">
        <Container className="container">
          <div className="backdrop-left">
            <OpenTime />
          </div>
          <div className="backdrop-right backdrop-content">
            <h5 className="antoki-title">Antoki</h5>
            {/* <h3 className="slogan">Naturally Delicious Vegan Cuisine</h3> */}
            <h3 className="slogan">The Asian Restaurant Authentic Cuisine</h3>
            {/* <h3 className="slogan">Natürlich Leckere Vegane Küche</h3> */}
            <br />
            {/* <Button
              className='menu-btn'
              text='VIEW MENU'
              width='300px'
              internalLink='/menu/'
              href={Pdf}
              target='_blank'
            /> */}
            <a href={Pdf} target="_blank">
              <Button
                text="VIEW MENU"
                width="300px"
                className="menu-btn"
              ></Button>
            </a>

            {/* <Button className="menu-btn" text="BOOK NOW" /> */}
          </div>
        </Container>
      </Backdrop>
      <div className="cta-book cta-info wrap-antoki-slogan">
        <p className="antoki-slogan">
          Im Antoki möchten wir Ihnen Woche für Woche weitere Speisen aus
          unserer Heimat vorstellen. Wir freuen uns, wenn Sie sich bei uns wohl
          fühlen und die vietnamesische Gastfreundschaft und Küche geniessen
        </p>

        {/* <Button text="MAKE A RESERVATION" color="black" /> */}
      </div>
      {/* <Menu data={data} /> */}
      <Highlight imgUrl={antokiHL} text="Today Chef's Choice" />
      {/* <GoogleMap /> */}
    </Layout>
  );
};

export default IndexPage;

export const data = graphql`
  query iamgeQuery {
    allImageSharp(
      filter: { fixed: { originalName: { regex: "/gao-food/" } } }
    ) {
      nodes {
        gatsbyImageData(width: 100, height: 100)
      }
    }
  }
`;
