import React from 'react';
import { Highlight } from '../styledComponent/sc';
import Button from './button';
import Pdf from '../ultilities/antoki-menu.pdf';
export default function highlight({ imgUrl, text }) {
  return (
    <Highlight imgUrl={imgUrl}>
      <div className='chefschoice'>
        <h1>{text}</h1>
        <h3>DAU OM </h3>
        <p>
          Gebratene Tofu Würfel in Tomaten Pfeffer Sauce. Als Beilage Duftreis.
        </p>
        {/* <Button
          text='VIEW MORE'
          color='#b05b3b'
          width='300px'
          internalLink='/menu/'
        /> */}
        <a href={Pdf} target='_blank'>
          <Button text='VIEW MORE' width='300px' className='menu-btn'></Button>
        </a>
      </div>
    </Highlight>
  );
}
